.container {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 80px);
}

.holder-messages {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
}

.bubble-container {
    margin-top: 6px;
    margin-bottom: 10px;
    display:flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 14px;
    /* margin-bottom: 8px; */
    /* align-items: center; */
}

.bubble {
    background-color: #F2F2F2;
    border-radius: 5px;
    box-shadow: 0 0 6px #B2B2B2;
    display: block;
    padding: 10px 18px;
    position: relative;
    vertical-align: top;
    word-wrap: break-word;
    /* color: white; */
}

/* Effect chat removed
.bubble::before {
    background-color: #F2F2F2;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform:             rotate( 29deg ) skew( -35deg );
    -moz-transform:    rotate( 29deg ) skew( -35deg );
    -ms-transform:     rotate( 29deg ) skew( -35deg );
    -o-transform:      rotate( 29deg ) skew( -35deg );
    -webkit-transform: rotate( 29deg ) skew( -35deg );
    width:  20px;
}
*/

.me {
    background-color: #59B9FF;
    margin-left: 60px;
    margin-right: 5px;
    align-self: flex-end;
    border-radius: 10px 10px 0 10px;
    color: white;
}

.me::before {
    box-shadow: 2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    right: -9px;
    background-color: #59B9FF;
}

.you {
    background-color: #eee;
    margin-left: 5px;
    margin-right:60px;
    align-self: flex-start;
    border-radius: 0 10px 10px 10px;
    color: black;
}

.you::before {
    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    left: -9px;
    background-color: #eee;
}

.you-hour {
    color: #a9afbb;
    font-size: 12px;
    margin-left: 5px;
}

.me-hour {
    color: #a9afbb;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-right: 8px;
}

.loader {
    color: #ff9800;
    /*display: flex;*/
    /*justify-content: center;*/
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.initial-message {
    color: #ff9800;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px;
    width: 50px;
}

.date-separator-line {
    width: 97%;
    color: #a9afbb;
    text-align:center;
    border-bottom: 1px solid #fff;
    line-height: 0.1em;
    margin: 20px 5px 15px;
}

.date-separator {
    background: #ffffff;
    padding:0 10px;
    font-size: 14px;
}
